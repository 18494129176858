// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-markdowns-1-1-what-is-ocelot-mdx": () => import("./../../../../src/markdowns/1.1-what-is-ocelot.mdx" /* webpackChunkName: "component---src-markdowns-1-1-what-is-ocelot-mdx" */),
  "component---src-markdowns-1-2-tools-and-building-blocks-mdx": () => import("./../../../../src/markdowns/1.2-tools-and-building-blocks.mdx" /* webpackChunkName: "component---src-markdowns-1-2-tools-and-building-blocks-mdx" */),
  "component---src-markdowns-1-3-defense-in-depth-mdx": () => import("./../../../../src/markdowns/1.3-defense-in-depth.mdx" /* webpackChunkName: "component---src-markdowns-1-3-defense-in-depth-mdx" */),
  "component---src-markdowns-2-1-architecture-mdx": () => import("./../../../../src/markdowns/2.1-architecture.mdx" /* webpackChunkName: "component---src-markdowns-2-1-architecture-mdx" */),
  "component---src-markdowns-2-2-concepts-mdx": () => import("./../../../../src/markdowns/2.2-concepts.mdx" /* webpackChunkName: "component---src-markdowns-2-2-concepts-mdx" */),
  "component---src-markdowns-2-3-quick-start-mdx": () => import("./../../../../src/markdowns/2.3-quick-start.mdx" /* webpackChunkName: "component---src-markdowns-2-3-quick-start-mdx" */),
  "component---src-markdowns-3-1-deploy-ocelot-mdx": () => import("./../../../../src/markdowns/3.1-deploy-ocelot.mdx" /* webpackChunkName: "component---src-markdowns-3-1-deploy-ocelot-mdx" */),
  "component---src-markdowns-3-2-c-sharp-integration-mdx": () => import("./../../../../src/markdowns/3.2-c-sharp-integration.mdx" /* webpackChunkName: "component---src-markdowns-3-2-c-sharp-integration-mdx" */),
  "component---src-markdowns-3-3-nodejs-integration-mdx": () => import("./../../../../src/markdowns/3.3-nodejs-integration.mdx" /* webpackChunkName: "component---src-markdowns-3-3-nodejs-integration-mdx" */),
  "component---src-markdowns-3-4-local-dev-mdx": () => import("./../../../../src/markdowns/3.4-local-dev.mdx" /* webpackChunkName: "component---src-markdowns-3-4-local-dev-mdx" */),
  "component---src-markdowns-4-1-how-to-oidc-mdx": () => import("./../../../../src/markdowns/4.1-how-to-oidc.mdx" /* webpackChunkName: "component---src-markdowns-4-1-how-to-oidc-mdx" */),
  "component---src-markdowns-4-2-how-to-ingress-mdx": () => import("./../../../../src/markdowns/4.2-how-to-ingress.mdx" /* webpackChunkName: "component---src-markdowns-4-2-how-to-ingress-mdx" */),
  "component---src-markdowns-5-1-configuration-mdx": () => import("./../../../../src/markdowns/5.1-configuration.mdx" /* webpackChunkName: "component---src-markdowns-5-1-configuration-mdx" */),
  "component---src-markdowns-5-2-api-docs-mdx": () => import("./../../../../src/markdowns/5.2-api-docs.mdx" /* webpackChunkName: "component---src-markdowns-5-2-api-docs-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

