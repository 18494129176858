module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js"},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/workspace/docs/.docz"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{},"src":"./","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":[],"mdPlugins":[],"hastPlugins":[],"ignore":[],"typescript":true,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"/workspace/docs/.docz","base":"/","source":"./","gatsby-root":null,"files":["src/markdowns/*.{md,markdown,mdx}","src/react-pages/*.{js,jsx,ts,tsx}"],"public":"/public","dest":".docz/dist","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"Ocelot Docs","description":"Documentation of Ocelot","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/workspace/docs","templates":"/workspace/docs/node_modules/docz-core/dist/templates","docz":"/workspace/docs/.docz","cache":"/workspace/docs/.docz/.cache","app":"/workspace/docs/.docz/app","appPackageJson":"/workspace/docs/package.json","appTsConfig":"/workspace/docs/tsconfig.json","gatsbyConfig":"/workspace/docs/gatsby-config.js","gatsbyBrowser":"/workspace/docs/gatsby-browser.js","gatsbyNode":"/workspace/docs/gatsby-node.js","gatsbySSR":"/workspace/docs/gatsby-ssr.js","importsJs":"/workspace/docs/.docz/app/imports.js","rootJs":"/workspace/docs/.docz/app/root.jsx","indexJs":"/workspace/docs/.docz/app/index.jsx","indexHtml":"/workspace/docs/.docz/app/index.html","db":"/workspace/docs/.docz/app/db.json"},"compilerOptions":{"jsx":"react","module":"commonjs","allowSyntheticDefaultImports":true,"esModuleInterop":true}},
    }]
